<template>
  <div class="notices-detail">
    <el-form ref="form" v-loading="loading" :model="notice" label-width="80px">
      <el-form-item label="关闭">
        <el-radio v-model="notice.closable" :label="1">可以</el-radio>
        <el-radio v-model="notice.closable" :label="0">不可以</el-radio>
      </el-form-item>
      <div class="title">中文公告</div>
      <el-form-item label="公告标题">
        <el-input v-model="notice.cn_title"></el-input>
      </el-form-item>
      <el-form-item label="公告内容">
        <el-input
          v-model="notice.cn_content"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item label="跳转链接">
        <el-input v-model="notice.cn_link"></el-input>
      </el-form-item>
      <el-form-item label="显示时间">
        <el-radio v-model="notice.cn_timing" label="always"
          >每次打开显示</el-radio
        >
        <el-radio v-model="notice.cn_timing" label="daily"
          >每日显示一次</el-radio
        >
        <el-radio v-model="notice.cn_timing" label="once">显示一次</el-radio>
      </el-form-item>

      <div class="title">英文公告</div>
      <el-form-item label="公告标题">
        <el-input v-model="notice.en_title"></el-input>
      </el-form-item>
      <el-form-item label="公告内容">
        <el-input
          v-model="notice.en_content"
          type="textarea"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item label="跳转链接">
        <el-input v-model="notice.en_link"></el-input>
      </el-form-item>
      <el-form-item label="显示时间">
        <el-radio v-model="notice.en_timing" label="always"
          >每次打开显示</el-radio
        >
        <el-radio v-model="notice.en_timing" label="daily"
          >每日显示一次</el-radio
        >
        <el-radio v-model="notice.en_timing" label="once">显示一次</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="handleGoList">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/configs/index";

const noticeApi = config.url + "/admin_wallet/api/notice";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notice: {
        // closable: "1",
        // timing: "always"
      },
      loading: false,
    };
  },
  created() {
    if (this.$route.params.id) {
      const id = this.$route.params && this.$route.params.id;
      this.getNoticeById(id);
    }
  },
  methods: {
    async getNoticeById(id) {
      try {
        const params = {};
        const { data } = await axios.get(`${noticeApi}/${id}`, {
          params,
        });
        this.notice = data.data;
        console.log(data.data.closable);
      } catch (error) {
        console.log(error);
      }
    },
    onSubmit() {
      if (this.isEdit) {
        this.updateNotice(this.notice.id, this.notice);
      } else {
        this.craeteNotice(this.notice);
      }
    },
    async craeteNotice(notice) {
      this.loading = true;
      try {
        const data = {
          ...notice,
        };
        const res = await axios.post(noticeApi, data);
        if (res && res.data && +res.data.code === 0) {
          this.loading = false;
          this.$message({ message: "成功", duration: 3000, type: "success" });
          this.handleGoList();
        } else {
          this.$message({
            message: res.data.msg,
            duration: 3000,
            type: "fail",
          });
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$message({ message: "新增失败", duration: 3000, type: "fail" });
      }
    },
    async updateNotice(id, notice) {
      this.loading = true;
      try {
        const data = {
          ...notice,
        };
        const res = await axios.put(`${noticeApi}/${id}`, data);
        if (res && res.data && +res.data.code === 0) {
          this.loading = false;
          this.$message({ message: "成功", duration: 3000, type: "success" });
          this.handleGoList();
        } else {
          this.$message({
            message: res.data.msg,
            duration: 3000,
            type: "fail",
          });
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$message({ message: "操作失败", duration: 3000, type: "fail" });
      }
    },
    handleGoList() {
      this.$router.replace("/app/notice");
    },
  },
};
</script>

<style lang="less" scoped>
.notices-detail {
  margin: 20px;

  .el-form {
    width: 560px;

    .title {
      margin: 20px 0;
    }
  }
}
</style>
