<template>
  <notices-detail :is-edit="true" />
</template>

<script>
import NoticesDetail from "./components/noticesDetail";

export default {
  name: "EditForm",
  components: { NoticesDetail },
};
</script>
